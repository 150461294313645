import "./BagePage.css";

import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

const BadgePage = () => {
    const {badgeId} = useParams();
    const [badgeData, setBadgeData] = useState(null);

    useEffect(() => {

        const fetchBadgeData = async () => {
            try {
                const response = await fetch(`https://attestation.esnna.biz/${badgeId}.json`);
                const data = await response.json();
                setBadgeData(data);
            } catch (error) {
                console.error(`Error fetching badge data: ${error}`);
                // const response = await fetch(`https://proof.esnna.biz/badge/${badgeId}.json`);
                // const text = await response.text();
                // console.error(`Error fetching badge data: ${text}`);
            }
        };

        fetchBadgeData();

    }, [badgeId]);

    return (
        <div className="badge-page">
            <div>
                <h1>Badge: {badgeId}</h1>
                <div className="badge">
                    <img src={`https://attestation.esnna.biz/${badgeId}.png`} alt={badgeId}/>
                </div>
            </div>
        </div>
    );
};

export default BadgePage;
