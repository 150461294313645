function App() {
  return (
    <div className="app">
      <header className="app-header">
        <h1>ESNNA Attestation Service</h1>
      </header>
    </div>
  );
}

export default App;
